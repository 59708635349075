import "styles/pages/cookies.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"

const Cookies = () => {
  const title = "Cookies"
  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />

      <section className="cookies">
        <div className="container-fluid">
          <h2>Kunning um nýtslu av farsporum (cookies)</h2>
          <p>
            <strong>Raska, MBM</strong> nýtir farspor á heimasíðuni www.mbm.fo /
            www.raska.fo fyri at kanna ferðsluna á heimasíðuni og fyri at gera
            tína uppliving so góða sum møguligt. Einstøk farspor innheinta
            persónsupplýsingar (upplýsingar um nýttu tóleindina, IP-adressu
            osfr.).
          </p>
          <p>
            Við hesi kunning vilja vit geva okkara vitjandi eitt greitt yvirlit
            yvir møguligu viðgerðina av personsupplýsingum, sum fer fram umvegis
            heimasíðuna, umframt hvørjar møguleikar tú hevur fyri at nokta hesi
            viðgerð.
          </p>
          <h4>Viðgerð av persónsupplýsingum umvegis farspor</h4>
          <p>
            Okkara nýtsla av farsporum kann viðføra viðgerð av
            persónsupplýsingum, og tískil viðmæla vit at tú eisini lesur okkara
            Dátuverndarkunning, sum lýsir okkara viðgerð av persónsupplýsingum
            sum heild, og ikki bara umvegis heimasíðuna, og eisini greiðir frá
            um tey rættindi, sum Dátuverndarlógin veitur tær.
          </p>
          <h4>Samtykki</h4>
          <p>
            Við at brúka okkara heimasíðu og trýsta ”Eg góðtaki” á okkara
            fráboðan um farspor á heimasíðuni, gevur tú samtykki til at Raska,
            MBM kann nýta farspor, sum lýst niðanfyri. Tú kanst afturkalla ella
            broyta títt samtykki við at broyta stillingarnar í yvirlitinum, eins
            og tú kanst blokera farspor í tínum kaga.
          </p>
          <h4>Hvat eru farspor?</h4>
          <p>
            Farspor eru smáar tekstfílur, sum verður goymd av tínum kaga
            (browsara) á tínari teldu, snildfon ella tilsvarandi við tí
            endamáli, at kenna aftur, minnast stillingar, gera hagfrøði, og
            málrætta lýsingar. Farspor merkir í hesi kunning og í samtykkinum
            eisini aðrar hættir fyri sjálvvirknari dátuinnsavnan, sum t.d.
            Flash-cookies (Local Shared Objects), Web Storage (HTML5),
            Javascripts ella farspor, ið verða sett við nýtslu av øðrum
            ritbúnaði. ”Farspor” fevnir eisini um upplýsingar um MAC-adressu og
            aðrar upplýsingar um tína tóleind.
          </p>
          <p>
            Farspor kunnu verða nýtt av <strong>Raska, MBM</strong> og av
            triðapørtum (sí yvirlitið).
          </p>
          <p>
            Grundleggjandi eru tvey sløg av farsporum: fyribils og verandi.
            Fyribils farspor er knýtt at tí aktuellu vitjanini og verða
            strikaðar sjálvvirkandi tá ið tú sløkkir kagan. Verandi farspor
            verða ikki strikað tí tey hava serliga funktión t.d. farspor, sum
            goyma frávalstillingar. Tú kanst sletta allar ávísar ella allar
            cookies, sum tín kagi hevur goymt umvegis stillingarnar á kaganum.
          </p>
          <h4>Hvørjar upplýsingar savna vit?</h4>
          <p>
            Tá tú ferðast á okkara síðu, savna vit upplýsingar um tína tóleind
            (teldu/teldil/snildfon). Hesar upplýsingar fevna um:
          </p>
          <ol>
            <li>Hvørji farspor verða goymd</li>
            <li>Slag av tóleind</li>
            <li>Slag av kaga (browser)</li>
            <li>Tíðarsonu</li>
            <li>IP-adressu</li>
            <li>Hvørjar síður, tú vitjar</li>
            <li>Hvussu tú fanst fram til okkara síðu</li>
          </ol>
          <p>
            Tøknin, vit nýta til innsavnan, er cookies sum eru dátufílur, sum
            verða goymdar á tíni tóleind.
          </p>
          <p>
            Tú kanst síggja hvørji farspor, ið verða nýtt{" "}
            <a href="https://cookiefirst.com" target="_blank" rel="noreferrer">
              her
            </a>
            .
          </p>
          <h4>Hvussu sleppur tú undan og strikar tú farspor?</h4>
          <p>
            Um tú ikki vilt hava, at heimasíða okkara leggur farspor á tína
            tóleind, kanst tú altíð afturkalla ella broyta títt samtykki við at
            broyta stillingarnar í yvirlitinum, eins og tú kanst forða fyri
            farsporum í tínum kaga. Hvussu tú forðar fyri farsporum valdast
            hvønn kaga tú nýtir. Um tú nýtir fleiri ymiskar kagar, er neyðugt at
            gera broytingar í øllum kagum.
          </p>
          <p>
            Um tú afturkallar samtykki, strikar ella forðar fyri farsporum, kann
            tað henda, at heimasíðan ikki virkar 100% optimalt, og at tú ikki
            fært atgongd til ávíst innihald.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Cookies
